//TODO: pass from server
export const UserRoleEnum = {
  ADMIN: 1,
  DEALER: 2,
  BACK_OFFICE: 3,
} as const;

export const NotificationTypeEnum = {
  TRANSFER: 1,
  DAMAGE: 2,
} as const;

export const ServiceTypeEnum = {
  TRANSFER: "AMOUNT_CARRYOVER",
  DAMAGE: "DAMAGE",
} as const;

export const ServiceStatusEnum = {
  PENDING: 1,
  REJECTED: 2,
  ACCEPTED: 3,
} as const;

export const SubscriptionType = {
  DEFAULT: 1,
  PREMIUM: 2,
  RETAIL: 3,
} as const;

export const UserStatusEnum = {
  BLOCKED: 0,
  PENDING: 1,
  ACTIVE: 2,
} as const;

//TODO: pass from server
export const ServiceCodeEnum = {
  AMOUNT_CARRYOVER: "AMOUNT_CARRYOVER",
  DAMAGE: "DAMAGE",
  DIVIDE_INTO_PARTS: "DIVIDE_INTO_PARTS",
  REQUEST_DIVIDE_CAR_INTO_PARTS: "REQUEST_DIVIDE_CAR_INTO_PARTS",
  REQUEST_FINANCING_FOR_A_CAR: "REQUEST_FINANCING_FOR_A_CAR",
} as const;
