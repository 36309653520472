<script setup lang="ts">
const __MACROS_props = withDefaults(defineProps<DefaultModalProps>(), {
  clickToClose: true,
  escToClose: true,
  //TODO: use service modal options
  width: '420px',
})
import { toRefs as __MACROS_toRefs } from "vue";
import {VueFinalModal} from 'vue-final-modal'

import {useModalOptions} from "~/composables/modal";

interface DefaultModalProps {
  title?: string
  width?: MaybeRef<string>,
  maxHeight?: string
  closable?: boolean
  clickToClose?: boolean,
  escToClose?: boolean,
}

const {clickToClose, escToClose, title, width, closable, maxHeight} = __MACROS_toRefs(__MACROS_props)

const {width: modalWidth} = useModalOptions({})

const {t} = useI18n({
  useScope: 'global',
})

const scrollContainer = ref(null);
const scroll = useScroll(scrollContainer)

const emit = defineEmits<{
  (e: 'confirm'): void
  (e: 'close'): void
  (e: 'refresh'): void
}>()

const onInnerClick = (e: MouseEvent) => {
  if (!clickToClose.value) {
    return
  }
  emit('update:modelValue', false)
}
</script>

<template>
  <VueFinalModal
    class="modal-container-v2"
    overlay-transition="vfm-fade"
    content-transition="vfm-fade"
    :click-to-close="clickToClose"
    :esc-to-close="escToClose"
  >
    <div
      class="inner-container"
      @click.self="onInnerClick"
    >
      <div class="modal-test">
        <div
          class="modal-container-v2-content"
          :style="{width: modalWidth}"
        >
          <div class="modal-header">
            <slot name="header">
              <span class="title">{{ t(title) }}</span>
              <button
                v-if="closable"
                class="btn-close"
                @click="emit('close')"
              >
                <img src="../../../assets/img/icons/close.svg">
              </button>
            </slot>
          </div>
          <div class="modal-body">
            <AppScrollContainer
              ref="scrollContainer"
              class="scrollContainer"
              :style="[{maxHeight}]"
            >
              <slot :scroll="scroll" />
            </AppScrollContainer>
          </div>
        </div>
      </div>
    </div>
  </VueFinalModal>
</template>

<style>
.confirm-modal {
  display: flex;
  justify-content: center;
  align-items: center;
}

.confirm-modal-content {
  display: flex;
  flex-direction: column;
  padding: 1rem;
  background: #fff;
  border-radius: 0.5rem;
}

.modal-test {
  max-width: 96%;
  padding: 3em 0 3em;
  margin-top: auto;
  margin-bottom: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.inner-container {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  overflow: auto;
  height: 100%;
}

.scrollContainer {
  overflow: auto;
}

</style>

<style lang="scss">
.modal-container-v2 {
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-container-v2-content {
  width: 420px;
  max-width: 100%;
  margin: 0px auto;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  transition: all 0.3s ease;
  font-family: Helvetica, Arial, sans-serif;
}

.modal-header {
  position: relative;
  display: flex;
  align-items: center;
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 22px;
  padding: 20px 50px 10px;

  color: #1F163B;
  //border-bottom: 1px solid linear-gradient(58.17deg, #2D2D86 0.79%, #F0494F 100%);;

  &::after {
    position: absolute;
    content: "";
    bottom: 0;
    left: 0;
    right: 0;
    height: 1px;
    background: linear-gradient(58.17deg, #2D2D86 0.79%, #F0494F 100%);
  }

  .btn-close {
    position: absolute;
    top: 20px;
    right: 20px;
  }
}

.modal-body {
  padding: 20px 50px;
}

.modal-default-button {
  float: right;
}

.modal-enter {
  opacity: 0;
}

.modal-leave-active {
  opacity: 0;
}

.modal-enter .modal-container-v2,
.modal-leave-active .modal-container-v2 {
  transform: scale(1.1);
}

:deep(*) {
  .ps {
    max-height: 520px !important;

    &.ps--active-y {
      padding-right: 15px;
      margin-right: -15px;
    }
  }

  .arrow {
    width: 16px;
    height: 14px;
    margin: 25px auto 5px;
    transform: rotate(90deg);
  }

  .indigo-ui-outer {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  .item-label {
    display: block;
    font-size: 12px;
    font-weight: 700;
    margin-bottom: 8px;
  }

  .two-columns {
    display: flex;
    max-width: 100%;
    overflow: hidden;

    .indigo-ui-outer {
      margin-right: 20px;

      &:last-child {
        margin: 0;
      }
    }
  }
}
</style>
