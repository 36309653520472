<script setup lang="ts">
const __MACROS_props = withDefaults(defineProps<{ value?: any }>(), {
    value: undefined
  })
import { toRefs as __MACROS_toRefs } from "vue";
const config = useAppConfig()


const {value} = __MACROS_toRefs(__MACROS_props)
</script>

<template>
  <DevOnly v-if="config.debug">
    <pre wrap>
      <slot>
        {{ value }}
      </slot>
    </pre>
  </DevOnly>
</template>

<style scoped>

</style>
