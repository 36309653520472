<script setup lang="ts">
const __MACROS_props = withDefaults(defineProps<ReadInboxProps>(), {})
import { toRefs as __MACROS_toRefs } from "vue";

import {useAuthStore} from "~/stores/authUser";

interface ReadInboxProps {
  allowClose: boolean
}

const {allowClose} = __MACROS_toRefs(__MACROS_props);


const emit = defineEmits<{
  (e: 'confirm'): void
  (e: 'close'): void
  (e: 'refresh'): void
}>()

const {t} = useI18n()

const submitHandler = async (formData,node) => {
  try {
    node.setErrors([])
    const {login, user} = useAuthStore()
    const {query} = useRoute()

    if (!user){
      throw new Error(t('error.auth.notLoggedIn'))
    }
    const client = useClient()

    await client.profile.changePassword.mutate({
      currentPassword: formData.currentPassword,
      password: formData.password,
      passwordConfirm: formData.password_confirm,
    })

    await login({
      username: user.username,
      password: formData.password
    })

    emit('confirm')
  } catch (e) {
    console.log(e)
    node.setErrors(
      [e.message],
    )
    return false;
  }
}

const abortWithLogout = async () => {
  emit('confirm')
  const authStore = useAuthStore();
  await authStore.logout();
};
</script>

<template>
  <div class="change-password">
    <div class="change-password__message">
      {{ allowClose ? t('modal.change-password.warningPasswordMessage') : t('modal.change-password.weakPasswordMessage') }}
    </div>
    <div class="change-password__message">
      <FormKit
        id="reset"
        v-slot="{ value, disabled }"
        type="form"
        :actions="false"
        @submit="submitHandler"
      >
        <FormKit
          type="password"
          name="currentPassword"
          :placeholder="t('modal.change-password.currentPassword')"
          autocomplete="off"
        />

        <FormKit
          type="password"
          name="password"
          :placeholder="t('common.labels.password')"
          validation="required|length:6|matches:/[^a-zA-Z]/"
          :validation-messages="{
            matches: 'Please include at least one symbol',
          }"
        />

        <FormKit
          type="password"
          name="password_confirm"
          :placeholder="t('common.labels.password_confirm')"
          validation="required|confirm"
        />

        <FormKit
          type="group"
          variant="inline"
        >
          <FormKit
            v-if="!allowClose"
            type="button"
            variant="outline"
            :label="t('modal.userTerms.logout')"
            @click="abortWithLogout"
          />
          <FormKit
            type="submit"
            variant="primary"
            width="full"
            :label="t('modal.change-password.submit')"
          />
        </FormKit>
      </FormKit>
    </div>
  </div>
</template>

<style lang="scss" scoped>

.change-password {
  &__subject {
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin-bottom: 16px;
  }

  &__message {
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-bottom: 24px;
  }
}
</style>
