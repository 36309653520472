import DefaultModal from "~/components/modal/core/DefaultModal.vue";
import CarRequestedService from "~/components/modal/content/CarRequestedService.vue";
import FullScreenModal from "~/components/modal/core/FullScreenModal.vue";
import GalleryModalContent from "~/components/modal/content/GalleryModalContent.vue";
import CreateInboxBatch from "~/components/modal/content/CreateInboxBatch.vue";
import ReadInboxBlockingMessage from "~/components/modal/content/ReadInboxBlockingMessage.vue";
import ClientRequestedService from "~/components/modal/content/ClientRequestedService.vue";

import {useClientServiceForm, useServiceForm} from "~/composables/api";
import {useSelectedCarsStore} from "~/stores/selected-cars";
import ManagerContactInfo from "~/components/modal/content/ManagerContactInfo.vue";
import DealerReport from "~/components/modal/content/DealerReport.vue";
import NoTitleModal from "~/components/modal/core/NoTitleModal.vue";
import ChangePassword from "~/components/modal/content/ChangePassword.vue";


type RequestServiceModalInput = {
  code: string
  carId: number
  vinFrom: string
  onRefresh: any
}

export const useModalOptions = (options: any) => {
  const width = useState('serviceModalWidth', () => '420px')
  const confirmMessage = useState('serviceModalConfirmMessage', () => null)


  const reset = () => {
    width.value = '420px'
    confirmMessage.value = null
  }

  const applySettings = (settings: any) => {
    if (settings?.width) {
      width.value = settings.width
    }
    if (settings?.confirmRequired) {
      confirmMessage.value = settings.confirm.message
    }
  }

  return {
    applySettings,
    width,
    confirmMessage,
    reset,
  }
}

export const useRequestServiceModal = async (input: RequestServiceModalInput) => {
  const carsStore = useSelectedCarsStore();
  const {width, reset, applySettings} = useModalOptions(input)

  reset()

  const {data, pending, error} = await useServiceForm({
    carId: input.carId ? +input.carId : undefined,
    carIds: carsStore.cars,
    code: input.code,
    formData: undefined
  });

  applySettings(data.value?.settings)

  if (error.value) {
    console.log(error.value)
    alert(error.value)
    throw error.value
  }

  const modal = useModal({
    component: DefaultModal,
    attrs: {
      title: `modal.${input.code}.title`,
      width: width,
      onConfirm: () => modal.close(),
      onClose: () => modal.close(),
      onRefresh: () => input?.onRefresh(),
    },
    slots: {
      default: useModalSlot({
        component: CarRequestedService,
        attrs: {
          ...input,
          form: data.value.form,
          onClose: () => modal.close(),
        }
      })
    },
  });
  return modal
}

export const useRequestClientServiceModal = async (input: RequestServiceModalInput) => {
  const {data: form, pending, error} = await useClientServiceForm({
    code: input.code,
    formData: undefined
  });

  if (error.value) {
    console.log(error.value)
    alert(error.value)
    throw error.value
  }

  const modal = useModal({
    component: DefaultModal,
    attrs: {
      title: `modal.${input.code}.title`,
      onConfirm: () => modal.close(),
      onClose: () => modal.close(),
      onRefresh: () => input?.onRefresh(),
    },
    slots: {
      default: useModalSlot({
        component: ClientRequestedService,
        attrs: {
          ...input,
          form,
          onClose: () => modal.close(),
        }
      })
    },
  });
  return modal
}


type GalleryModalInput = {
  slides: string[]
  index: Ref<number>
  onNext?: any
  onPrev?: any
  onGoTo?: any
}
export const useGalleryModal = async (input: GalleryModalInput) => {
  const modal = useModal({
    component: FullScreenModal,
    attrs: {
      onClose: () => modal.close(),
    },
    slots: {
      default: useModalSlot({
        component: GalleryModalContent,
        attrs: {
          ...input,
          onClose: () => modal.close(),
        }
      })
    },
  });
  return modal
}
type InboxCreateInput = undefined
export const useInboxCreateModal = async (input: InboxCreateInput, services: any) => {
  const {t} = services

  const {width, reset, applySettings} = useModalOptions(input)

  reset()

  applySettings({
    width: '1200px'
  })

  const modal = useModal({
    component: DefaultModal,
    attrs: {
      title: 'modal.inbox.create.title',
      onClose: () => modal.close(),
    },
    slots: {
      default: useModalSlot({
        component: CreateInboxBatch,
        attrs: {
          onClose: () => modal.close(),
        }
      })
    },
  });
  return modal
}


type DealerReportInput = undefined

export const useDealerReportModal = async (input: DealerReportInput) => {
  const modal = useModal({
    component: DefaultModal,
    attrs: {
      title: 'modal.dealer-report.title',
      onClose: () => modal.close(),
    },
    slots: {
      default: useModalSlot({
        component: DealerReport,
        attrs: {
          onClose: () => modal.close(),
        }
      })
    },
  });
  return modal
}

type InboxReadInput = undefined
export const useInboxReadBlockingModal = async (input: InboxReadInput, services: any) => {
  const client = useClient()

  const {data} = await client.inbox.getMessage.useQuery()

  const message = unref(data)

  if (!message) {
    return
  }

  const modal = useModal({
    component: DefaultModal,
    attrs: {
      title: 'modal.inbox.read.title',
      onClose: () => modal.close(),
      clickToClose: false,
      escToClose: false,
    },
    slots: {
      default: useModalSlot({
        component: ReadInboxBlockingMessage,
        attrs: {
          message,
          onClose: () => modal.close(),
          onConfirm: async () => {
            client.inbox.read.mutate({
              id: message.id
            })
            modal.close()
          },
        }
      })
    },
  });
  setTimeout(() => {
    modal.open()
  }, 1500)
}

export const useChangePasswordModal = async (input: any, services: any) => {

  const modal = useModal({
    component: DefaultModal,
    attrs: {
      title: 'modal.change-password.title',
      onClose: () => modal.close(),
      clickToClose: !!input?.allowClose,
      escToClose: !!input?.allowClose,
    },
    slots: {
      default: useModalSlot({
        component: ChangePassword,
        attrs: {
          //TODO: refactor with policy usage directly
          allowClose: !!input?.allowClose,
          onClose: () => modal.close(),
          onConfirm: async () => {
            //TODO: relogin?
            modal.close()
          },
        }
      })
    },
  });

  return modal
}

type ManagerContactInfoInput = undefined
export const useManagerContactInfoModal = async (input: ManagerContactInfoInput) => {
  const modal = useModal({
    component: NoTitleModal,
    attrs: {
      title: 'modal.manager.title',
      onClose: () => modal.close(),
      clickToClose: true,
      escToClose: true,
    },
    slots: {
      default: useModalSlot({
        component: ManagerContactInfo,
        attrs: {
          onClose: () => modal.close(),
          onConfirm: async () => {
            modal.close()
          },
        }
      })
    },
  });

  return modal
}
