<script setup lang="ts">
const __MACROS_props = withDefaults(defineProps<CreateInboxBatchProps>(), {})
import { toRefs as __MACROS_toRefs } from "vue";


interface CreateInboxBatchProps {
}

// eslint-disable-next-line no-empty-pattern
const {} = __MACROS_toRefs(__MACROS_props);

const emit = defineEmits<{
  (e: 'confirm'): void
  (e: 'close'): void
  (e: 'refresh'): void
}>()

const {t} = useI18n({
  useScope: 'global'
})

const {preferences} = toRefs(useAppConfig());

const typeOptions = [
  {label: t('modal.dealer-report.type.full'), value: 'full'},
  {label: t('modal.dealer-report.type.short'), value: 'short'},
]

if (preferences.value.withSubdealerReport){
  typeOptions.push({label: t('modal.dealer-report.type.subDealers'), value:'subDealers'})
}

const onModalSubmit = async (formData, node) => {
  try {
    node.setErrors([])
    const client = useClient()
    const result = await client.car.dealerReport.mutate({
      ...formData
    })
    reflectResponse(result)
    emit('close')
  } catch (e) {
    node.setErrors(
      [e.message],
    )
    return false;
  }
}

const data = reactive({
  form: {
    type: "full",
  }
})

</script>

<template>
  <div class="create-dealer-report">
    <FormKit
      v-model="data.form"
      type="form"
      :actions="false"
      @submit="onModalSubmit"
    >
      <FormKit
        type="radio"
        name="type"
        :options="typeOptions"
        validation="required"
        :label="t('modal.dealer-report.type')"
      />
      <div class="two-columns">
        <FormKit
          type="submit"
          variant="primary"
          :label="t('modal.dealer-report.submit')"
        />
      </div>
    </FormKit>
  </div>
</template>

<style lang="scss" scoped>

</style>
