
export default function formKitRadioPlugin() {
  return (node) => {
    node.on('created', () => {
      const updateValueIfInvalid = () => {
        const options = node.props.options || [];
        if (!options.some(option => (option.__original || option.value) === node.value ) && node.value !== undefined) {
          node.input(options[0]?.__original || options[0]?.value );
        }
      };

      node.on('prop:options', updateValueIfInvalid);
    });

  };
}
