import {FormKitTypeDefinition} from '@formkit/core'
import {
  outer,
  inner,
  help,
  boxHelp,
  messages,
  message,
  prefix,
  suffix,
  fieldset,
  decorator,
  box,
  legend,
  boxOption,
  boxOptions,
  boxWrapper,
  boxLabel,
  disablesChildren as disables,
  options,
  radios,
  $if,
  $extend, icon,
  defaultIcon
} from "@formkit/inputs"
import './radio.scss'
import { outerSection } from "~/libs/formkit/utils";
import formKitRadioPlugin from "../../plugins/remove-invalid-value";

/**
 * Input definition for a radio(ess).
 * @public
 */

const radioOuter = outerSection({
  attrs: {
    'data-variant': "$variant || 'primary'",
    'data-color': "$color || 'default'",
    'data-size': "$size || 'medium'",
    'data-width': "$width || 'normal'"
  }
})
export const radio: FormKitTypeDefinition = {
  schema: radioOuter(
    $if(
      '$options == undefined',
      /**
       * Single radio structure.
       */
      boxWrapper(
        inner(prefix(), box(), decorator(
          $if('$value === true', icon('checkedIcon')),
          $if('$value !== true', icon('uncheckedIcon')),
        ), suffix()),
        $if('$label', boxLabel('$label'))
      ),
      /**
       * Multi radio structure.
       */
      fieldset(
        legend('$label'),
        help('$help'),
        boxOptions(
          boxOption(
            boxWrapper(
              inner(
                prefix(),
                $extend(box(), {
                  bind: '$option.attrs',
                  attrs: {
                    id: '$option.attrs.id',
                    value: '$option.value',
                    checked: '$fns.isChecked($option.value)',
                  },
                }),
                decorator(
                  $if('$fns.isChecked($option.value) === true', icon('checkedIcon')),
                  $if('$fns.isChecked($option.value) !== true', icon('uncheckedIcon')),
                ),
                suffix()
              ),
              $if('$option.label', boxLabel('$option.label'))
            ),
            boxHelp('$option.help')
          )
        )
      )
    ),
    // Help text only goes under the input when it is a single.
    $if('$options === undefined && $help', help('$help')),
    messages(message('$message.value'))
  ),
  /**
   * The type of node, can be a list, group, or input.
   */
  type: 'input',
  /**
   * The family of inputs this one belongs too. For example "text" and "email"
   * are both part of the "text" family. This is primary used for styling.
   */
  family: 'box',
  /**
   * An array of extra props to accept for this input.
   */
  props: ['options', 'onValue', 'offValue', 'optionsLoader','variant', 'color', 'size', 'width'],
  /**
   * Additional features that should be added to your input
   */
  features: [
    defaultIcon('checkedIcon', 'radioChecked'),
    defaultIcon('uncheckedIcon', 'radio'),
    disables,
    options,
    radios,
    formKitRadioPlugin()
  ],
}
