<script lang="ts" setup>
// setResponseStatus(404)
const props = defineProps({
  error: Object
})
</script>

<template>
  <div class="error-page">
    <div class="container">
      <div class="image-wrapper">
        <img src="~/assets/img/pages/404/variant-1.png">
      </div>

      <div class="content">
        <h2 class="title">
          500 Error
        </h2>
        <p class="text">
          error
        </p>
        <div class="btn-wrapper">
          <NuxtLink class="link">
            <FormKit 
              type="button"
              variant="outline"
              label="Вернуться"
            />
          </NuxtLink>
        </div>

        <ConsoleLog>
          {{ JSON.stringify(props.error, null, 2) }}
        </ConsoleLog>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>

.error-page {
  position: relative;
  min-height: 100vh;
  display: flex;
  flex-direction: column;

  padding: 50px 0 0;
  
  background-image: url(/assets/img/pages/auth/bg.png);
  background-position: top right;
  background-size: auto;
  background-repeat: no-repeat;

  .image-wrapper {
    display: flex;
    justify-content: center;
    margin-bottom: 30px;
    img {
      width: 550px;
      height: auto;
      object-fit: contain;
    }
  }

  .content {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;

    .title {
      font-weight: 700;
      font-size: 30px;
      margin-bottom: 20px;
    }

    .text {
      margin-bottom: 20px;
    }

    .btn-wrapper {
      ::deep * {
        margin: 0;
      }
    }
  }
}

</style>
