import {useAuthStore} from "../stores/authUser";
import {useChangePasswordModal} from "~/composables/modal";

export default defineNuxtPlugin(() => {
  const onceOpened = useState('security-force-change-password', () => false)

  addRouteMiddleware(async (to, from) => {
    if (import.meta.server) {
      return;
    }

    if (onceOpened.value){
      return;
    }

    const authStore = useAuthStore();

    if (to.path.indexOf('/auth') !== -1 ) {
      return;
    }

    if (!authStore.user) {
      return;
    }

    if (!authStore.user?.preferences?.forceChangePassword) {
      return;
    }

    //TODO: refactor with vfm?
    onceOpened.value = true;

    //TODO: check better way to block ui
    const modal = await useChangePasswordModal(undefined, {})

    setTimeout(() => {
      modal?.open()
    }, 1500)
  })
})
